import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Spinner } from 'react-bootstrap'
import { getWorkerChainRun, getWorkerChainRunVersion } from '../../api/workerChain'
import { formatDistanceToNow, parseISO } from 'date-fns'
import MarkdownPreview from '@uiw/react-markdown-preview'
import PreviewComponent from '../../components/PreviewComponent'
import { WorkerChainRun, WorkerChainRunVersion } from '../WorkerChainBuilder/types'
import { getWorkerRunVersion } from '../../api/worker'
import { Edge, Node } from 'reactflow'
import { DisplayType, WorkerOutput } from '../../components/Interfaces'
import ChainOfThoughtTools from '../../components/WorkerCotTools'
import CopyToClipboardButton from '../../components/CopyToClipboardButton'
import { useAuth0 } from '@auth0/auth0-react'


const WorkerChainRunPreview: React.FC = () => {
	const { workerChainId, runId, versionId } = useParams<{ workerChainId: string; runId: string; versionId: string }>()
	const [workerOutputs, setWorkerOutputs] = useState<WorkerOutput[]>([])
	const [loading, setLoading] = useState(true)
	const [channelName, setChannelName] = useState('')
	const [workerChainName, setWorkerChainName] = useState('')
	const [time, setTime] = useState('')
	const { isAuthenticated } = useAuth0()

	const fetchWorkerChainRun = useCallback(async () => {
		setLoading(true)
		try {
			if (workerChainId && runId && versionId) {
				let response = await getWorkerChainRun(workerChainId, runId)
				const workerChainRun = response.data as WorkerChainRun
				setChannelName(workerChainRun.input.channel_name)
				setWorkerChainName(workerChainRun.worker_chain_name)
				const reportNodes = JSON.parse(workerChainRun.nodes).filter(
					(nd: Node) => nd.type == '_output' && nd.data.id == 'report'
				)
				if (reportNodes.length > 0) {
					const reportNode = reportNodes[0]
					const workerNodeIds = JSON.parse(workerChainRun.edges)
						.filter((edge: Edge) => edge.target == reportNode.id)
						.map((edge: Edge) => edge.source)
					const allInputWorkerNodes = JSON.parse(workerChainRun.nodes)
						.filter((nd: Node) => nd.type == 'worker' && workerNodeIds.includes(nd.id))
					const reportWorkerNodes = reportNode.data.orderedWorkers ?? allInputWorkerNodes
					response = await getWorkerChainRunVersion(workerChainId, runId, versionId)
					const workerChainRunVersion = response.data as WorkerChainRunVersion
					setTime(workerChainRunVersion.created_at)
					const completed: {[key: string]: boolean} = {}
					for (const reportWorkerNode of reportWorkerNodes) {
						for (const workerRunVersionTuple of workerChainRunVersion.output.workers) {
							if (reportWorkerNode.data.id === workerRunVersionTuple.worker_id && !completed[workerRunVersionTuple.version_id]) {
								const workerResponse = await getWorkerRunVersion(
									workerRunVersionTuple.worker_id,
									workerRunVersionTuple.run_id,
									workerRunVersionTuple.version_id
								)
								setWorkerOutputs((oldWorkerOutputs) => [...oldWorkerOutputs, workerResponse.data.output])
								completed[workerRunVersionTuple.version_id] = true
								break
							}
						}
					}
				}
			}
		} catch (error) {
			console.error('Error fetching worker chain run:', error)
		} finally {
			setLoading(false)
		}
	}, [workerChainId, runId, versionId])

	useEffect(() => {
		fetchWorkerChainRun()
	}, [workerChainId, runId])

	const formatDate = (dateString: string) => {
		const date = parseISO(dateString)
		return 'Last Updated ' + formatDistanceToNow(date, { addSuffix: true })
	}

	if (loading) {
		return (
			<Container fluid className='d-flex justify-content-center align-items-center' style={{ height: '100vh' }}>
				<Spinner animation='border' />
			</Container>
		)
	}

	return (
		<Container fluid className='ps-4 pe-2'>
			<h1>
				{channelName} - {workerChainName}
			</h1>
			<div className='my-4'>
				{workerOutputs?.map(({ title, content, display_type, tool_interactions }) => {
					const displayType = display_type as DisplayType
					return (
						<div key={content} className='mb-4'>
							<h2>{title}</h2>
							{isAuthenticated && <ChainOfThoughtTools toolInteractions={tool_interactions} />}
							{displayType == DisplayType.TEXT ? (
								<div style={{ position: 'relative' }}>
									<div style={{ 
										position: 'sticky',
										top: 0,
										zIndex: 100
									}}>
										<div style={{
											// Uses absolute positioning for floating over content
											position: 'absolute', 
											top: 10,
											right: 10
										}}>
											<CopyToClipboardButton 
												content={content}
												style={{ margin: '0' }}
											/>
										</div>
									</div>
									<MarkdownPreview
										source={content}
										style={{ backgroundColor: 'inherit', color: 'inherit' }}
										wrapperElement={{
											"data-color-mode": "light"
										}}
									/>
								</div>
							) : displayType == DisplayType.REACT_COMPONENT ? (
								<PreviewComponent code={content} />
							) : displayType == DisplayType.IMAGE ? (
								<img src={content} />

							) : displayType == DisplayType.VIDEO ? (
								<video controls src={content}>
									Your browser does not support the video tag.
								</video>	
							) : (
								<p>Work Unit Type {displayType} not supported yet</p>
							)}
						</div>
					)
				})}
			</div>
			<div>
				<b>
					<i style={{ color: 'rgb(80, 95, 121)' }}>{time && formatDate(time)}</i>
				</b>
			</div>
		</Container>
	)
}

export default WorkerChainRunPreview
