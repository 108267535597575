import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import ReactFlow, {
	Background,
	BackgroundVariant,
	Edge,
	EdgeChange,
	Connection,
	Node,
	NodeChange,
	ReactFlowInstance,
	applyEdgeChanges,
	applyNodeChanges,
} from 'reactflow'
import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap'
import {
	fetchTemplate as fetchTemplateApi,
	fetchWorkerChains as fetchWorkerChainsApi,
	saveWorkerChain,
	deleteWorkerChain,
	runWorkerChain as runWorkerChainApi,
	fetchWorkerChainRuns,
	getVersionsForWorkerChainRun,
	resumeWorkerChainRun,
} from '../../api/workerChain'
import { listAllChannels } from '../../api/channels'
import { addEdge } from 'reactflow'
import { ChannelNode, InputNode, OutputNode, WorkerNode } from './nodes'
import { Section, Template, WorkerChain, WorkerChainRun, WorkerChainRunVersion } from './types'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ChannelInterface } from '../../components/Interfaces'
import { format, parseISO } from 'date-fns'
import { FaPlus, FaUpload, FaPlay, FaTrash, FaCheck, FaTimes, FaCopy } from 'react-icons/fa'
import 'reactflow/dist/style.css'
import './index.css'
import { Worker, WorkerModal, WorkOutputType } from '../../components/Worker Modal'
import { MdDescription, MdImage, MdCode, MdVideocam } from 'react-icons/md'
import { Autocomplete, TextField } from '@mui/material'

const nodeTypes = {
	channel: ChannelNode,
	// used underscore as reactflow doesn't display allow input and output types
	_input: InputNode,
	_output: OutputNode,
	worker: WorkerNode,
}

interface ExpandableSectionProps extends Section {
	handleCreateWorker?: () => void
	isCreatingWorker?: boolean
}

const OutputTypeIcon = ({ type }: { type: string }) => {
	switch (type) {
		case 'TEXT':
			return <MdDescription title="Text Output" />
		case 'IMAGE':
			return <MdImage title="Image Output" />
		case 'REACT_COMPONENT':
			return <MdCode title="React Component Output" />
		case 'VIDEO':
			return <MdVideocam title="Video Output" />
		default:
			return null
	}
}

const ExpandableSection = ({ 
	node_templates, 
	title, 
	type,
	handleCreateWorker,
	isCreatingWorker 
}: ExpandableSectionProps) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const [templateSearchQuery, setTemplateSearchQuery] = useState('')
	const [typeFilter, setTypeFilter] = useState('')

	const templateOptions = useMemo(() => 
		node_templates.map(template => ({
			label: template.title,
			...template
		})).sort((a, b) => a.label.localeCompare(b.label)),
		[node_templates]
	)

	const sortedNodeTemplates = useMemo(
		() => {
			return templateOptions.filter(template => 
				template.label.toLowerCase().includes(templateSearchQuery.toLowerCase()) &&
				(!typeFilter || template.output_type === typeFilter)
			)
		},
		[templateOptions, templateSearchQuery, typeFilter]
	)

	return (
		<div className='expandable-section'>
			<h3 onClick={() => setIsExpanded(!isExpanded)} className='expandable-heading'>
				{title}
				<span className={`expand-icon ${isExpanded ? 'expanded' : ''}`}>
					{isExpanded ? '▼' : '▶'}
				</span>
			</h3>
			{isExpanded && (
				<div className='available-nodes'>
					{type === 'worker' && (
						<Button
							variant="dark"
							className="node-item mb-3"
							onClick={handleCreateWorker}
							disabled={isCreatingWorker}
							style={{
								opacity: isCreatingWorker ? '0.7' : '1.0',
								pointerEvents: isCreatingWorker ? 'none' : 'auto',
								width: '100%',
								backgroundColor: '#24272a',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{isCreatingWorker ? (
								<Spinner animation="border" size="sm" />
							) : (
								<FaPlus size={16} />
							)}
							<span style={{marginLeft: '5px'}}>Create Worker</span>
						</Button>
					)}
					{/* Search functionality for worker and channel nodes */}
					{(type === 'worker' || type === 'channel') && (
						<Autocomplete
							value={templateOptions.find(opt => opt.label === templateSearchQuery) || null}
							onChange={(_, newValue) => {
								setTemplateSearchQuery(newValue?.label || '')
							}}
							options={templateOptions}
							getOptionLabel={(option) => option.label}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Search templates"
									variant="outlined"
									size="small"
									style={{ 
										backgroundColor: 'white',
										borderRadius: '4px'
									}}
								/>
							)}
							style={{ marginBottom: '10px' }}
						/>
					)}
					{/* Filter workers based on worker outputs */}
					{type === 'worker' && (
						<TextField
							select
							value={typeFilter}
							onChange={(e) => setTypeFilter(e.target.value)}
							variant="outlined"
							size="small"
							style={{ 
								backgroundColor: 'white',
								borderRadius: '4px',
								marginBottom: '10px',
								width: '100%'
							}}
							SelectProps={{
								native: true
							}}
						>
							<option value="">All Types</option>
							{Object.entries(WorkOutputType).map(([value, name]) => (
								<option key={value} value={value}>
									{name}
								</option>
							))}
						</TextField>
					)}
					{sortedNodeTemplates.map((template) => {
						const reactFlowNode = {
							type,
							data: template,
						}
						return (
							<div
								key={template.id}
								className='node-item'
								draggable
								onDragStart={(event) => {
									event.dataTransfer.setData('application/reactflow', JSON.stringify(reactFlowNode))
								}}>
								{type == 'worker' && template.output_type && (
									<span style={{ padding: '1px'}}>
										<OutputTypeIcon type={template.output_type}/>
									</span>
								)} {" "}
								{template.title}
							</div>
						)
					})}
				</div>
			)}
		</div>
	)
}

interface ControlButton {
	name: string
	icon: React.ReactNode
	onClick: () => void
	disabled: boolean
}

interface ControlBarProps {
	buttons: ControlButton[]
}

const ControlBar = ({ buttons }: ControlBarProps) => {
	const [loadingButtonIndex, setLoadingButtonIndex] = useState<number>()

	const handleButtonClick = async (index: number) => {
		setLoadingButtonIndex(index)
		await buttons[index].onClick()
		setLoadingButtonIndex(undefined)
	}

	return (
		<div className='control-bar'>
			{buttons.map((button, index) => {
				return (
					<div className='control-button-wrapper' key={button.name}>
						<button
							className={`control-button ${button.disabled ? 'disabled' : ''}`}
							onClick={() => handleButtonClick(index)}
							disabled={button.disabled || loadingButtonIndex === index}
							title={button.name}>
							{loadingButtonIndex === index ? (
								<Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
							) : (
								button.icon
							)}
						</button>
					</div>
				)
			})}
		</div>
	)
}

const WorkerChainBuilder: React.FC = () => {
	const [nodes, setNodes] = useState<Node[]>([])
	const [edges, setEdges] = useState<Edge[]>([])
	const [template, setTemplate] = useState<Template>()
	const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance>()
	const [workerChainName, setWorkerChainName] = useState('Untitled WorkFlow')
	const [selectedWorkerChain, setSelectedWorkerChain] = useState<WorkerChain>()
	const [workerChains, setWorkerChains] = useState<WorkerChain[]>([])
	const [showRunModal, setShowRunModal] = useState(false)
	const [selectedChannel, setSelectedChannel] = useState('')
	const [channels, setChannels] = useState<ChannelInterface[]>([])
	const [loadingChannels, setLoadingChannels] = useState(false)
	const [isRunning, setIsRunning] = useState(false)
	const [mode, setMode] = useState<'edit' | 'runs'>('edit')
	const [executions, setExecutions] = useState<WorkerChainRun[]>([])
	const [selectedExecution, setSelectedExecution] = useState<WorkerChainRun>()
	const [versions, setVersions] = useState<WorkerChainRunVersion[]>([])
	const [selectedVersion, setSelectedVersion] = useState<WorkerChainRunVersion>()
	const isFetchingVersions = useRef(false)
	const numberOfWorkers = nodes.filter((nd) => nd.type == 'worker').length
	const [showWorkerModal, setShowWorkerModal] = useState(false)
	const [workerFormData, setWorkerFormData] = useState<Worker>()
	const [isCreatingWorker, setIsCreatingWorker] = useState(false)
	const readyToResume =
		mode == 'runs' &&
		selectedVersion &&
		selectedVersion.output.workers &&
		selectedVersion.output.workers?.length != numberOfWorkers &&
		selectedVersion.output.status == 'SUCCESS'
	const reactFlowWrapper = useRef<HTMLDivElement>(null)
	const navigate = useNavigate()
	const [executionSearchQuery, setExecutionSearchQuery] = useState('')

	const fetchTemplate = async () => {
		fetchTemplateApi().then((response) => {
			setTemplate(response.data)
		})
	}

	const fetchWorkerChains = async () => {
		const response = await fetchWorkerChainsApi()
		console.log('setting worker chains', response.data)
		setWorkerChains(response.data)
		return response.data
	}

	const fetchVersions = useCallback(
		async (execution?: WorkerChainRun, selectVersionId?: string, force = false) => {
			if (selectedWorkerChain?.id && execution && (force || !isFetchingVersions.current)) {
				isFetchingVersions.current = true
				try {
					const response = await getVersionsForWorkerChainRun(selectedWorkerChain.id, execution.run_id)
					setVersions(
						response.data.sort(
							(a: WorkerChainRunVersion, b: WorkerChainRunVersion) =>
								new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
						)
					)
					console.log('setting versions', response.data)
					response.data.forEach((version: WorkerChainRunVersion) => {
						if (version.version_id === (selectVersionId ?? execution.current_version_id)) {
							setSelectedVersion(version)
						}
					})
				} catch (error) {
					console.error('Error fetching versions:', error)
				} finally {
					isFetchingVersions.current = false
				}
			}
		},
		[selectedWorkerChain]
	)

	const handleExecutionSelect = useCallback(
		(execution: WorkerChainRun) => {
			setSelectedExecution(execution)
			fetchVersions(execution, undefined, true)
		},
		[fetchVersions]
	)

	const fetchExecutionsForWorkerChain = useCallback(
		async (workerChainId: string) => {
			try {
				const response = await fetchWorkerChainRuns(workerChainId)
				const sortedExecutions = response.data.sort(
					(a: WorkerChainRun, b: WorkerChainRun) =>
						new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
				)
				setExecutions(sortedExecutions)
				if (!selectedExecution) {
					handleExecutionSelect(sortedExecutions[0])
				}
			} catch (error) {
				console.error('Error fetching executions:', error)
				toast.error('Error fetching workflow runs')
			}
		},
		[fetchWorkerChainRuns, handleExecutionSelect, selectedExecution]
	)

	const onWorkerFeedback = useCallback(
		(newVersionId: string) => {
			if (selectedWorkerChain && selectedExecution) {
				fetchExecutionsForWorkerChain(selectedWorkerChain.id!)
				fetchVersions(selectedExecution, newVersionId, true)
			}
		},
		[selectedWorkerChain, selectedExecution]
	)

	const normalizeObject = (obj: any): any => {
		if (Array.isArray(obj)) {
			return obj.map(normalizeObject).sort();
		}
		if (obj !== null && typeof obj === 'object') {
			return Object.keys(obj)
				.sort()
				.reduce((result: any, key) => {
					result[key] = normalizeObject(obj[key]);
					return result;
				}, {});
		}
		return obj;
	};

	const areNodesEqual = (nodes1: Node[], nodes2: Node[]) => {
		if (nodes1.length !== nodes2.length) return false;
		
		// Sort both arrays by ID to ensure consistent ordering
		const sorted1 = [...nodes1].sort((a, b) => a.id.localeCompare(b.id));
		const sorted2 = [...nodes2].sort((a, b) => a.id.localeCompare(b.id));
		
		// Compare each node's essential properties
		return sorted1.every((node1, index) => {
			const node2 = sorted2[index];
			const normalizedNode1 = normalizeObject({
				id: node1.id,
				type: node1.type,
				position: node1.position,
				data: node1.data
			});
			const normalizedNode2 = normalizeObject({
				id: node2.id,
				type: node2.type,
				position: node2.position,
				data: node2.data
			});
			return JSON.stringify(normalizedNode1) === JSON.stringify(normalizedNode2);
		});
	};

	// this is a hack to force reactflow to re-render when the nodes are updated
	const rerenderReactFlowWithNewNodes = (newNodes: Node[]) => {
		if (areNodesEqual(nodes, newNodes)) {
			return
		}
		setNodes([])
		setTimeout(() => {
			setNodes(newNodes)
		}, 0)
	}

	useEffect(() => {
		fetchTemplate()
		fetchWorkerChains()
	}, [])

	useEffect(() => {
		if (mode === 'runs') {
			setVersions([])
			setSelectedExecution(undefined)
			setSelectedVersion(undefined)
			if (selectedWorkerChain?.id) {
				fetchExecutionsForWorkerChain(selectedWorkerChain.id)
			}
		} else if (mode === 'edit') {
			setExecutions([])
			setVersions([])
			setSelectedExecution(undefined)
			setSelectedVersion(undefined)
			if (selectedWorkerChain) {
				const nds = JSON.parse(selectedWorkerChain.nodes)
				nds.forEach((node: Node) => (node.data.setNodes = setNodes))
				rerenderReactFlowWithNewNodes(nds)
				setEdges(JSON.parse(selectedWorkerChain.edges))
				setWorkerChainName(selectedWorkerChain.name)
			} else {
				setNodes([])
				setEdges([])
				setWorkerChainName('Untitled WorkFlow')
			}
		}
	}, [selectedWorkerChain, mode])

	useEffect(() => {
		if (mode == 'runs' && selectedExecution && selectedVersion && selectedWorkerChain) {
			const refreshVersions = () => {
				fetchExecutionsForWorkerChain(selectedWorkerChain.id!)
				fetchVersions(selectedExecution, selectedVersion.version_id)
			}
			const intervalId = setInterval(refreshVersions, 5000) // Auto refresh every 5 seconds

			return () => clearInterval(intervalId) // Clean up on unmount
		}
	}, [mode, selectedWorkerChain, selectedExecution, selectedVersion])

	useEffect(() => {
		if (selectedWorkerChain && selectedExecution && selectedVersion && mode == 'runs') {
			const nds = JSON.parse(selectedExecution.nodes)
			nds.forEach((node: Node) => {
				node.data.mode = 'view'
				node.data.worker_chain_id = selectedWorkerChain.id
				node.data.worker_chain_run_id = selectedExecution.run_id
				node.data.worker_chain_run_version_id = selectedVersion.version_id
				node.data.onFeedback = onWorkerFeedback
				if (selectedVersion.output?.workers?.length > 0) {
					// update the output for the worker node
					for (const output of selectedVersion.output.workers) {
						// TODO: The condition [output.node_id == null] is a hack to handle the case where node_id is null for older runs
						if (output.worker_id == node.data.id && (output.node_id == null || output.node_id == node.id)) {
							node.data['output'] = output
							node.data['status'] = 'SUCCESS'
						}
					}
				}
				if(selectedVersion.output?.running_workers?.length > 0) {
					// update the status for the running worker node
					for (const output of selectedVersion.output.running_workers) {
						if (output.worker_id == node.data.id && output.node_id == node.id) {
							node.data['status'] = 'RUNNING'
						}
					}
				}
			})
			rerenderReactFlowWithNewNodes(nds)
			setEdges(JSON.parse(selectedExecution.edges))
		}
	}, [mode, selectedWorkerChain, selectedExecution, selectedVersion, onWorkerFeedback])

	const formatDateTime = (dateTimeString: string) => {
		const date = parseISO(dateTimeString)
		return format(date, 'MMMM d, yyyy h:mm a')
	}

	const onNodesChange = useCallback(
		(changes: NodeChange[]) => setNodes((nds) => applyNodeChanges(changes, nds)),
		[setNodes]
	)

	const refreshReportNodes = useCallback((newEdges: Edge[]) => {
		setNodes(nds =>
			nds.map(nd => {
				if (nd.data.id === "report") {
					let orderedWorkers = newEdges.filter(edge => edge.target === nd.id)
						.map(edge => nds.filter(node => node.id === edge.source)[0])
						.filter((workerNode): workerNode is Node => workerNode !== undefined)
					if (nd.data.orderedWorkers) {
						orderedWorkers = orderedWorkers.sort((a, b) => {
							const indexA = nd.data.orderedWorkers.findIndex((worker: Node) => worker.id === a.id)
							const indexB = nd.data.orderedWorkers.findIndex((worker: Node) => worker.id === b.id)
							if (indexA == -1) {
								return 1
							} else if (indexB == -1) {
								return -1
							}
							return indexA - indexB
						})
					}
					return {
						...nd,
						data: {
							...nd.data,
							orderedWorkers
						}
					}
				}
				return nd
			})
		)
	}, [setNodes])

	const onEdgesChange = useCallback(
		(changes: EdgeChange[]) => {
			setEdges((eds: Edge[]) => {
				const newEdges = applyEdgeChanges(changes, eds);
				refreshReportNodes(newEdges);
				return newEdges;
			})
		},
		[setEdges]
	)

	const onConnect = useCallback(
		(connection: Connection) => {
			console.log('creating connection', connection)
			const sourceNode = nodes.find((node) => node.id === connection.source)
			const targetNode = nodes.find((node) => node.id === connection.target)

			if (sourceNode && targetNode &&
				((connection.sourceHandle == connection.targetHandle) ||
					(connection.sourceHandle == 'output' && connection.targetHandle?.startsWith('output')))
			) {
				setEdges((eds) => {
					const newEdges = addEdge(connection, eds);
					if (targetNode.data.id == "report") {
						refreshReportNodes(newEdges);
					}
					return newEdges;
				})
			} else {
				console.log('Invalid connection: source id not same as target id')
				toast.error('Invalid Connection')
			}
		},
		[nodes, setEdges]
	)

	const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault()
		event.dataTransfer.dropEffect = 'move'
	}, [])

	const onDrop = useCallback(
		(event: React.DragEvent<HTMLDivElement>) => {
			if (reactFlowInstance && reactFlowWrapper.current && mode === 'edit') {
				event.preventDefault()

				const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
				const node = event.dataTransfer.getData('application/reactflow')

				if (typeof node === 'undefined' || !node) {
					return
				}

				const position = reactFlowInstance.project({
					x: event.clientX - reactFlowBounds.left,
					y: event.clientY - reactFlowBounds.top,
				})
				const newNode = JSON.parse(node) as Node
				newNode.position = position
				newNode.id = `${newNode.type}-${Math.random().toString(36).substring(2, 9)}`
				newNode.data.setNodes = setNodes

				console.log(`Adding new node "${newNode.id}" at ${JSON.stringify(position)}`)

				setNodes((nds) => nds.concat(newNode))
			}
		},
		[reactFlowInstance, mode]
	)

	const handleBackClick = () => {
		navigate(-1)
	}

	const handleCreateWorker = useCallback(() => {
		try {
			setIsCreatingWorker(true)
			setWorkerFormData(undefined)
			setShowWorkerModal(true)
		} catch (error) {
			console.error('Error opening worker modal:', error)
			toast.error('Failed to open worker creation form')
		} finally {
			setIsCreatingWorker(false)
		}
	}, [])

	const handleWorkerChainSelect = useCallback(
		(workerChainId: string) => {
			const workerChainToSelect = workerChains.find((workerChain) => workerChain.id === workerChainId)
			if (workerChainToSelect) {
				setSelectedWorkerChain(workerChainToSelect)
			}
		},
		[workerChains]
	)

	const handleNewClick = () => {
		setMode('edit')
		setNodes([])
		setEdges([])
		setWorkerChainName('Untitled WorkFlow')
		setSelectedWorkerChain(undefined)
	}

	const handleSaveClick = useCallback(async () => {
		const workerChain: WorkerChain = {
			name: workerChainName,
			nodes: JSON.stringify(nodes),
			edges: JSON.stringify(edges),
		}
		try {
			const response = await saveWorkerChain(selectedWorkerChain?.id, workerChain)
			console.log('WorkerChain saved:', response.data)
			toast.success('WorkerFlow saved successfully!')
			await fetchWorkerChains()
			setSelectedWorkerChain(response.data)
		} catch (error) {
			console.error('Error saving worker chain:', error)
			toast.error('Error saving workflow')
		}
	}, [selectedWorkerChain, nodes, edges, workerChainName])

	const handleDuplicateClick = useCallback(async () => {
		if (selectedWorkerChain) {
			// Create deep copies of nodes and edges with modified IDs
			const clonedNodes = nodes.map(node => ({
				...node,
				id: `${node.id}-copy`
			}))

			const clonedEdges = edges.map(edge => ({
				...edge,
				id: `${edge.id}-copy`,
				source: `${edge.source}-copy`,
				target: `${edge.target}-copy`,
			}))

			const duplicatedWorkerChain: WorkerChain = {
				name: `${workerChainName} (Copy)`,
				nodes: JSON.stringify(clonedNodes),
				edges: JSON.stringify(clonedEdges),
			}
			try {
				const response = await saveWorkerChain(undefined, duplicatedWorkerChain)
				console.log('WorkerChain duplicated:', response.data)
				toast.success('WorkerFlow duplicated successfully!')
				await fetchWorkerChains()
				setSelectedWorkerChain(response.data)
			} catch (error) {
				console.error('Error duplicating worker chain:', error)
				toast.error('Error duplicating workflow')
			}
		}
	}, [selectedWorkerChain, workerChainName, nodes, edges, fetchWorkerChains, setNodes, setEdges])

	const handleDeleteClick = useCallback(async () => {
		if (selectedWorkerChain && selectedWorkerChain.id) {
			if (window.confirm('Are you sure you want to delete this workflow?')) {
				try {
					await deleteWorkerChain(selectedWorkerChain.id)
					toast.success('WorkerFlow deleted successfully!')
					await fetchWorkerChains()
					setSelectedWorkerChain(undefined)
				} catch (error) {
					console.error('Error deleting worker chain:', error)
					toast.error('Error deleting workflow')
				}
			}
		}
	}, [selectedWorkerChain])

	const handleRunClick = useCallback(async () => {
		setShowRunModal(true)
		setLoadingChannels(true)
		listAllChannels().then((res) => {
			const sortedChannels = (res?.data?.channels ?? []).sort((a: any, b: any) =>
				a.displayName.localeCompare(b.displayName)
			)
			setChannels(sortedChannels)
			setLoadingChannels(false)
		})
	}, [])

	const handleResumeClick = useCallback(async () => {
		if (selectedWorkerChain && selectedExecution && selectedVersion) {
			resumeWorkerChainRun(selectedWorkerChain.id!, selectedExecution.run_id, selectedVersion.version_id)
				.then((res: any) => {
					toast.success('WorkerChain resumed successfully!')
					fetchVersions(selectedExecution, res.data.version_id, true)
				})
				.catch((err: any) => {
					toast.error('Error resuming worker chain')
					console.error('Error resuming worker chain', err)
				})
		}
	}, [selectedWorkerChain, selectedExecution, selectedVersion])

	const validateInputNodes = () => {
		return nodes.every(currentNode => {
			console.log(currentNode)

			if (currentNode.type !== '_input') return true;

			const validationRules = {
				text: (node: Node) => {
					return node.data?.content?.length > 0;
				},
				file: (node: Node) => {
					return node.data?.key?.length > 0;
				},
				url: (node: Node) => {
					return node.data?.url?.length > 0;
				}
			};
	
			const validateNode = validationRules[currentNode.data.id as keyof typeof validationRules];

			if (validateNode) {
				const isValid = validateNode(currentNode);
				if (!isValid) {
					console.warn(`Validation failed for node:`, currentNode);
				}
				return isValid;
			}
	
			console.warn(`Unhandled node ID: '${currentNode.id}', skipping validation.`);
			return false;
		});
	};

	const runWorkerChain = useCallback(async () => {
		if (selectedWorkerChain?.id && selectedChannel) {
			if (!validateInputNodes()) {
				toast.error('Please fill in all input nodes before running');
				return;
			}
			try {
				setIsRunning(true)
				runWorkerChainApi(selectedWorkerChain, selectedChannel, nodes, edges)
					.then((res) => {
						setMode('runs')
						fetchExecutionsForWorkerChain(selectedWorkerChain.id!)
						handleExecutionSelect(res.data)
						setShowRunModal(false)
					})
					.finally(() => setIsRunning(false))
			} catch (err) {
				console.error('Error running workflow:', err)
				toast.error('Error running workflow')
			}
		}
	}, [selectedWorkerChain, selectedChannel, nodes, edges])

	const onRunModalClose = () => {
		setShowRunModal(false)
		setChannels([])
		setSelectedChannel('')
	}

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if ((event.metaKey || event.ctrlKey) && event.key === 's') {
				event.preventDefault()
				handleSaveClick()
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [handleSaveClick])

	const controlBarButtons =
		mode == 'edit'
			?
			[
				{
					name: 'New',
					icon: <FaPlus />,
					onClick: handleNewClick,
					disabled: selectedWorkerChain == undefined,
				},
				{
					name: 'Save (Ctrl+S)',
					icon: <FaUpload />,
					onClick: handleSaveClick,
					disabled:
						nodes.length == 0 ||
						(JSON.stringify(nodes) == selectedWorkerChain?.nodes &&
							JSON.stringify(edges) == selectedWorkerChain?.edges &&
							workerChainName == selectedWorkerChain?.name),
				},
				{
					name: 'Run',
					icon: <FaPlay />,
					onClick: handleRunClick,
					disabled: selectedWorkerChain == undefined,
				},
				{
					name: 'Duplicate',
					icon: <FaCopy />,
					onClick: handleDuplicateClick,
					disabled: nodes.length == 0
				},
				{
					name: 'Delete',
					icon: <FaTrash />,
					onClick: handleDeleteClick,
					disabled: selectedWorkerChain == undefined,
				},
			]
			:
			[
				{
					name: 'Resume Flow',
					icon: <FaPlay />,
					onClick: handleResumeClick,
					disabled: !readyToResume,
				},
			]

	const filteredExecutions = executions.filter(execution => 
		execution.input.channel_name.toLowerCase().includes(executionSearchQuery.toLowerCase())
	)

	const getUniqueChannelNames = useMemo(() => {
		const uniqueNames = [...new Set(executions.map(exec => exec.input.channel_name))]
		return uniqueNames.map(name => ({ label: name }))
	}, [executions])

	return (
		<Container fluid className='outer-container'>
			<Row className='d-flex flex-column h-100'>
				<Col md={2} className='sidebar'>
					<div className='mt-2'>
						<strong className='' style={{ cursor: 'pointer', fontSize: 30 }} onClick={handleBackClick}>
							←
						</strong>
					</div>
					<div className='worker-chains-selector mt-2 mb-4'>
						<Autocomplete
							value={selectedWorkerChain || null}
							onChange={(_, newValue) => {
								handleWorkerChainSelect(newValue?.id || '')
							}}
							options={workerChains}
							getOptionLabel={(option) => option.name}
							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Search Existing WorkFlow"
									variant="outlined"
									size="small"
									style={{ 
										backgroundColor: 'white',
										borderRadius: '4px'
									}}
									inputProps={{
										...params.inputProps,
										style: { color: 'black' }
									}}
								/>
								)}
						/>
					</div>
					{selectedWorkerChain && (
						<div style={{ position: 'relative' }}>
							<hr style={{ position: 'absolute', top: '0%', width: '100%', zIndex: 1 }} />
							<div className='d-flex justify-content-center my-4'>
								<Button
									variant='outline-light'
									size='sm'
									onClick={() => setMode('edit')}
									active={mode === 'edit'}
									className='mode-button'>
									Edit
								</Button>
								<Button
									variant='outline-light'
									size='sm'
									onClick={() => setMode('runs')}
									active={mode === 'runs'}
									className='mode-button'>
									Runs
								</Button>
							</div>
						</div>
					)}
					{mode === 'edit' && (
						<>
							<div className='floating-label-input my-4'>
								<input
									type='text'
									value={workerChainName}
									onChange={(e) => setWorkerChainName(e.target.value)}
									className='worker-chain-name-input'
									id='workerChainNameInput'
									placeholder=''
								/>
								<label htmlFor='workerChainNameInput'>Name</label>
							</div>
							{template &&
								template.sections.map((section) => (
									<ExpandableSection key={section.title} {...section} {...(section.type === 'worker' ? {
										handleCreateWorker,
										isCreatingWorker
									} : {})} />
								))}
						</>
					)}
					{mode == 'runs' && (
						<div className='executions-list'>
							<h3>Runs</h3>
							<Autocomplete
								value={executionSearchQuery ? { label: executionSearchQuery } : null}
								onChange={(_, newValue) => {
									setExecutionSearchQuery(newValue?.label || '')
								}}
								options={getUniqueChannelNames}
								getOptionLabel={(option) => option.label}
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder="Select channel"
										variant="outlined"
										size="small"
										style={{ 
											backgroundColor: 'white',
											borderRadius: '4px',
											marginBottom: '10px'
										}}
									/>
								)}
							/>
							{filteredExecutions.length ? (
								filteredExecutions.map((execution) => (
									<div
										key={execution.run_id}
										className='execution-item'
										onClick={() => handleExecutionSelect(execution)}>
										<div className='execution-content'>
											<div className='execution-title'>{execution.input.channel_name}</div>
											<div
												className='execution-subtitle'
												style={{
													color: '#999',
													fontStyle: 'italic',
													fontSize: '0.8em',
												}}>
												{formatDateTime(execution.created_at)}
											</div>
										</div>
										<div className='execution-status'>
											{execution.status === 'SUCCESS' ? (
												<FaCheck color='green' title='Success' />
											) : execution.status === 'FAILED' ? (
												<FaTimes color='red' title='Failed' />
											) : (
												<Spinner
													animation='border'
													variant='warning'
													size='sm'
													title='In Progress'
												/>
											)}
										</div>
									</div>
								))
							) : (
								<div>Run flow to see executions</div>
							)}
						</div>
					)}
				</Col>
				<Col md={10}>
					<div style={{ height: '100vh' }} ref={reactFlowWrapper} className='reactflow-wrapper'>
						<ReactFlow
							onInit={setReactFlowInstance}
							nodeTypes={nodeTypes}
							nodes={nodes}
							edges={edges}
							onNodesChange={onNodesChange}
							onEdgesChange={mode === 'edit' ? onEdgesChange : undefined}
							onConnect={mode === 'edit' ? onConnect : undefined}
							onDrop={onDrop}
							onDragOver={onDragOver}
							nodesConnectable={mode === 'edit'}>
							{(nodes.length != 0 || selectedWorkerChain) && <ControlBar buttons={controlBarButtons} />}
							{mode === 'runs' && versions.length > 0 && (
								<div style={{ position: 'absolute', top: 10, left: 10, zIndex: 4 }}>
									<label htmlFor='version-select' style={{ marginRight: '10px', fontWeight: 'bold' }}>
										Version:
									</label>
									<select
										id='version-select'
										value={selectedVersion?.version_id}
										onChange={(e) =>
											setSelectedVersion(
												versions.filter((v) => v.version_id == e.target.value)[0]
											)
										}
										style={{ padding: '5px', borderRadius: '4px' }}>
										<option value='' disabled>
											Select Version
										</option>
										{versions.map((version) => (
											<option key={version.version_id} value={version.version_id}>
												{formatDateTime(version.created_at)}
											</option>
										))}
									</select>
								</div>
							)}

							<Background variant={BackgroundVariant.Dots} gap={12} size={1} />
						</ReactFlow>
					</div>
				</Col>
			</Row>
			<Modal show={showRunModal} onHide={onRunModalClose}>
				<Modal.Header closeButton>
					<Modal.Title>{workerChainName}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{loadingChannels ? (
						<Spinner as='span' animation='border' role='status' aria-hidden='true' />
					) : (
						<Form>
							<Form.Group className='mb-3'>
								<Form.Label>Channel</Form.Label>
								<Form.Select
									value={selectedChannel}
									onChange={(e) => setSelectedChannel(e.target.value)}
									disabled={isRunning}
									required>
									<option value=''>Select a channel</option>
									{channels.map((channel) => (
										<option key={channel.id} value={channel.id}>
											{channel.displayName}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Form>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={runWorkerChain} disabled={loadingChannels || isRunning}>
						{isRunning && (
							<Spinner
								as='span'
								animation='border'
								size='sm'
								role='status'
								aria-hidden='true'
								className='mx-2'
							/>
						)}
						Run
					</Button>
				</Modal.Footer>
			</Modal>
			<WorkerModal
				workerFormData={workerFormData}
				setWorkerFormData={setWorkerFormData}
				showModal={showWorkerModal}
				setShowModal={setShowWorkerModal}
				onSubmit={async () => {
					await fetchTemplate()
					toast.success('Worker created successfully!')
				}}
			/>
		</Container>
	)
}

export default WorkerChainBuilder
