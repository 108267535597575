import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import posthog from 'posthog-js'

const useAuthenticateUser = () => {
	const { isAuthenticated, user, isLoading } = useAuth0()
	const [userAuthenticated, setUserAuthenticated] = useState<boolean | null>(null)
	const navigate = useNavigate()

	posthog.identify(user?.email)

	// Set email as a property of the person
	posthog.people.set({
		email: user?.email,
	})

	const emails = [
		'priyanshu.yadav@trilogy.com',
		'nauman.ahmed@trilogy.com',
		'rishabh.raizada@trilogy.com',
		'vinayak.sachdeva@codenation.co.in',
		'aman.chandra@codenation.co.in',
	]

	if (user?.email && !emails.includes(user?.email)) {
		// alert('hello');
		posthog.startSessionRecording()
	}

	useEffect(() => {
    console.log(`isAuthenticated: ${isAuthenticated}`)
    console.log(`user: ${user}`)
    console.log(`isLoading: ${isLoading}`)
    if (isLoading) {
      return
    }
		if (isAuthenticated && user?.email) {
			const allowedEmails = [
				'trilogy.com',
				'devfactory.com',
				'fixated.co',
				'codenation.co.in',
				'totogi.com',
				'cloudfix.com',
				'skyvera.com',
			]
			if (allowedEmails.some((email) => user.email?.endsWith(email))) {
				setUserAuthenticated(true)
			} else {
				toast.error('Invalid email, only trusted domains are allowed.')
				setUserAuthenticated(false)
				navigate('/')
			}
		} else {
			setUserAuthenticated(false)
		}
	}, [isAuthenticated, user, isLoading])

	return userAuthenticated
}

export default useAuthenticateUser
