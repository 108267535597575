import React, { useState, useEffect } from 'react'
import { Button, Container, Dropdown, Form, ListGroup, Modal, Spinner, InputGroup } from 'react-bootstrap'
import {
	fetchWorkers as fetchWorkersApi,
	deleteWorker,
	runWorker,
	getWorkerRunVersion,
	fetchWorkerRuns as fetchWorkerRunsApi,
	listAssociatedWorkflows as listAssociatedWorkflowsApi,
} from '../../api/worker'
import { listAllChannels } from '../../api/channels'
import { toast } from 'react-toastify'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { WorkerModal, WorkOutputType, Worker } from '../../components/Worker Modal'
import './index.css'

interface Channel {
	id: string
	displayName: string
}

export interface WorkerRunInput {
	channel_id: string
	channel_name: string
}

export interface WorkerRunOutput {
	content: string
	display_type: string
}

export interface WorkerRun {
	run_id: string
	worker_id: string
	worker_name: string
	input: WorkerRunInput
	output: WorkerRunOutput
	created_at: string
	error: string
}

const WorkerRunsDropdown: React.FC<{ workerId: string }> = ({ workerId }) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [runsData, setRunsData] = useState<WorkerRun[]>([])

	const fetchWorkerRuns = async (workerIdToFetch: string) => {
		try {
			const response = await fetchWorkerRunsApi(workerIdToFetch)
			const sortedRuns = (response?.data ?? []).sort(
				(a: WorkerRun, b: WorkerRun) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
			)
			setRunsData(sortedRuns)
		} catch (error) {
			console.error(`Failed to fetch worker runs ${error}`)
			toast.error('Failed to fetch worker runs. Please try again later.')
			setRunsData([])
		}
	}

	const handleToggle = (isOpenNew: boolean) => {
		setIsOpen(isOpenNew)
		if (isOpenNew) {
			setIsLoading(true)
			fetchWorkerRuns(workerId).then((runs) => setIsLoading(false))
		}
	}

	return (
		<Dropdown onToggle={handleToggle} show={isOpen} align='end'>
			<Dropdown.Toggle variant='primary' id={`dropdown-${workerId}`}>
				Runs
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{isLoading ? (
					<Dropdown.Item disabled>
						<Spinner animation='border' size='sm' /> Loading...
					</Dropdown.Item>
				) : runsData.length === 0 ? (
					<Dropdown.Item disabled>No recent runs</Dropdown.Item>
				) : (
					runsData.map((run: WorkerRun) => (
						<Dropdown.Item
							key={run.run_id}
							onClick={() => window.open(`/worker/${workerId}/run/${run.run_id}`, '_blank')}>
							{run.input.channel_name} -{' '}
							{formatDistanceToNow(parseISO(run.created_at), { addSuffix: true })}
						</Dropdown.Item>
					))
				)}
			</Dropdown.Menu>
		</Dropdown>
	)
}

const WorkerManagement = () => {
	const [workers, setWorkers] = useState<Worker[]>([])
	const [showModal, setShowModal] = useState(false)
	const [showRunModal, setShowRunModal] = useState(false)
	const [channels, setChannels] = useState<Channel[]>([])
	const [selectedChannel, setSelectedChannel] = useState('')
	const [selectedWorker, setSelectedWorker] = useState<Worker>()
	const [isRunning, setIsRunning] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [workerFormData, setWokrerFormData] = useState<Worker>()
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [workerIdToDelete, setWorkerIdToDelete] = useState<string>('')
	const [associatedWorkflows, setAssociatedWorkflows] = useState<any[]>([])
	const [searchQuery, setSearchQuery] = useState<string>('')
	const [typeFilter, setTypeFilter] = useState<string>('')

	const fetchWorkers = async () => {
		const res = await fetchWorkersApi()
		const wks = res?.data ?? []
		const sortedWorkers = [...wks].sort(
			(a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
		)
		setWorkers(sortedWorkers)
	}

	const fetchChannels = async () => {
		const res = await listAllChannels()
		const sortedChannels = (res?.data?.channels ?? []).sort((a: any, b: any) =>
			a.displayName.localeCompare(b.displayName)
		)
		setChannels(sortedChannels)
	}

	const listAssociatedWorkflows = async (workerId: string) => {
		const res = await listAssociatedWorkflowsApi(workerId)
		console.log('associated workflows:', res)
		return res?.data ?? []
	}

	useEffect(() => {
		setIsLoading(true)
		Promise.all([fetchWorkers(), fetchChannels()]).then(() => setIsLoading(false))
	}, [])

	const handleDelete = async (id: string) => {
		const workflows = await listAssociatedWorkflows(id)
		setAssociatedWorkflows(workflows)
		setWorkerIdToDelete(id)
		setShowDeleteModal(true)
	}

	const confirmDelete = async () => {
		try {
			await deleteWorker(workerIdToDelete)
			await fetchWorkers()
			setShowDeleteModal(false)
		} catch (error) {
			console.error('Error deleting worker:', error)
			toast.error('Error deleting worker')
		}
	}

	const handleRun = async () => {
		setIsRunning(true)
		try {
			const response = await runWorker(selectedWorker!.id, selectedChannel)
			const { run_id, current_version_id } = response.data

			// Poll for the output
			const pollInterval = 5000 // 5 seconds
			const maxAttempts = 180 // 15 minute total polling time
			let attempts = 0

			const pollForOutput = async () => {
				attempts++
				const versionResponse = await getWorkerRunVersion(selectedWorker!.id, run_id, current_version_id)

				if (versionResponse.data.output.status === 'SUCCESS') {
					// Output is available
					setShowRunModal(false)
					setIsRunning(false)
					window.open(`/worker/${selectedWorker!.id}/run/${run_id}/version/${current_version_id}`)
				} else if (versionResponse.data.output.status === 'FAILED') {
					// Worker run failed
					setShowRunModal(false)
					setIsRunning(false)
					toast.error('Worker run failed. Please try again later.')
				} else if (attempts < maxAttempts) {
					// Continue polling
					setTimeout(pollForOutput, pollInterval)
				} else {
					// Timeout reached
					toast.warn('Worker is still processing. You can check the results later.')
					setShowRunModal(false)
					setIsRunning(false)
				}
			}

			pollForOutput()
		} catch (error) {
			console.error('Error running worker:', error)
			toast.error('Error running worker')
			setIsRunning(false)
		}
	}

	const handleRunModalOpen = (worker: Worker) => {
		setSelectedWorker(worker)
		setShowRunModal(true)
	}

	const handleEdit = (worker: any) => {
		setWokrerFormData(worker)
		setShowModal(true)
	}

	const filteredWorkers = workers.filter(worker =>
		worker.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
		(typeFilter === '' || worker.output_type === typeFilter)
	)

	return (
		<Container>
			{isLoading ? (
				<div className='d-flex justify-content-center mt-4'>
					<Spinner animation='border' variant='primary' />
				</div>
			) : (
				<>
					<div className='d-flex justify-content-between align-items-center mt-4'>
						<h1>Workers</h1>
						<Button variant='primary' onClick={() => setShowModal(true)} className='me-2'>
							Create New Worker
						</Button>
					</div>
					<div className='d-flex align-items-center mb-3'>
						<InputGroup>
							<InputGroup.Text>
								<i className="fa-solid fa-magnifying-glass"></i>
							</InputGroup.Text>
							<Form.Control
								type='text'
								placeholder='Search workers'
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>
							<Form.Select
								value={typeFilter}
								onChange={(e) => setTypeFilter(e.target.value)}
								style={{ maxWidth: '200px' }}
							>
								<option value=''>All Types</option>
								{Object.entries(WorkOutputType).map(([value, name]) => (
									<option key={value} value={value}>
										{name}
									</option>
								))}
							</Form.Select>
						</InputGroup>
					</div>
					<ListGroup>
						{filteredWorkers.length > 0 ? (
							filteredWorkers.map((worker) => (
								<ListGroup.Item
									key={worker.id}
									className='d-flex justify-content-between align-items-center pt-4'>
									<div>
										<h5>{worker.name}</h5>
										<p>
											Output Type:{' '}
											{WorkOutputType[worker.output_type as keyof typeof WorkOutputType]}
										</p>
									</div>
									<div className='d-flex'>
										<Button variant='info' className='me-2' onClick={() => handleEdit(worker)}>
											Edit
										</Button>
										<Button
											variant='danger'
											className='me-2'
											onClick={() => handleDelete(worker.id)}>
											Delete
										</Button>
										<Button
											variant='success'
											className='me-2'
											onClick={() => handleRunModalOpen(worker)}>
											Run
										</Button>
										<WorkerRunsDropdown workerId={worker.id} />
									</div>
								</ListGroup.Item>
							))
						) : (
							<div className='text-center mt-4'>
								<h4>No Workers Available</h4>
								<p>Create a new worker to get started.</p>
							</div>
						)}
					</ListGroup>

					<WorkerModal
						workerFormData={workerFormData}
						setWorkerFormData={setWokrerFormData}
						onSubmit={async () => await fetchWorkers()}
						showModal={showModal}
						setShowModal={setShowModal}
					/>

					<Modal show={showRunModal} onHide={() => setShowRunModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>{selectedWorker?.name}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Form.Group className='mb-3'>
									<Form.Label>Channel</Form.Label>
									<Form.Select
										value={selectedChannel}
										onChange={(e) => setSelectedChannel(e.target.value)}
										disabled={isRunning}
										required>
										<option value=''>Select a channel</option>
										{channels.map((channel) => (
											<option key={channel.id} value={channel.id}>
												{channel.displayName}
											</option>
										))}
									</Form.Select>
								</Form.Group>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='primary' onClick={handleRun} disabled={isRunning}>
								{isRunning && (
									<Spinner
										as='span'
										animation='border'
										size='sm'
										role='status'
										aria-hidden='true'
										className='mx-2'
									/>
								)}
								Run
							</Button>
						</Modal.Footer>
					</Modal>

					<Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
						<Modal.Header closeButton>
							<Modal.Title>Confirm Deletion</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<p>Are you sure you want to delete this worker?</p>
							{associatedWorkflows?.length > 0 && (
								<div className="text-danger">
									Warning: This worker is associated with the following workflows:
									<ul>
										{associatedWorkflows.map((wf: any) => (
											<li key={`workflow-${wf.id}`}>{wf.name}</li>
										))}
									</ul>
								</div>
							)}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
								Cancel
							</Button>
							<Button variant="danger" onClick={confirmDelete}>
								Delete
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
		</Container>
	)
}

export default WorkerManagement
